import _, { constant } from 'lodash';
import { from, of } from 'rxjs';
import { groupBy, mergeMap, toArray, tap } from 'rxjs/operators';
import { SortOrder } from '../models/common.model';
import Decimal from 'decimal.js';


export const splitCamelCase = (str: string): string => {
    return str ? str.replace(/([a-z0-9])([A-Z])/g, '$1 $2') : str;
}

export const InitialCapitalCase = (str: string): string => {
    return str ? str.split(' ').map(((w: string) => w.charAt(0).toUpperCase() + w.substring(1))).join(' ') : str;
}

export const splitInitCapitalCamelCase = (str: string): string => {
    //return str ? InitialCapitalCase(str.replace(/([a-z0-9])([A-Z])/g, '$1 $2')) : str;
    const result = str?.replace(/([A-Z])/g, " $1");
    return ((result?.charAt(0)?.toUpperCase() + result?.slice(1)) || "").trim();
}

export const sortBy = (input: Array<any>, key: string) => {
    return _.sortBy(input, [function (o) { return o[key]; }]);
}
export const sortByDate = (input: Array<any>, key: string) => {
    return _.sortBy(input, [function (o) { return o[key]; }]);
}
/// 
/// 
/// @param {Array<any>} input - 
/// @param {Array<SortOrder>} sortParams - 
/// @returns {any} - 
export const orderBy = (input: Array<any>, sortParams: Array<SortOrder>) => {
    return _.orderBy(input, sortParams.map(param => param.key), sortParams.map(param => param.order));
}

export const GetValidDateOrNull = (date: string) => {
    if (date && new Date(date).getFullYear() < 1900) {
        return null;
    }
    //console.log("date", date, date == "0001-01-01" || date == "0001-01-01T00:00:00" || date == "null" || date == "undefined" || date == undefined ? null : date);

    return date == "0001-01-01" || date == "0001-01-01T00:00:00" || date == "null" || date == "undefined" || date == undefined ? null : date;
}

export const GroupByKey = (allItems: Array<any>, groupKey: string, splitCamelCase = false) => {

    return new Promise((resolve, reject) => {
        const itemGroups: Array<any> = [];
        if (!groupKey || !allItems?.length) {
            resolve(itemGroups);
            return;
        }
        const items = from(allItems || []).pipe(
            groupBy((doc) => doc[groupKey]),
            mergeMap((group) => {
                var items = [];
                group.forEach(item => items.push(item));
                return of((
                    {
                        key: splitCamelCase ? splitInitCapitalCamelCase(group?.key || "Other") : group?.key,
                        items: items//orderBy(items, [{ key: 'expiryDate', order: 'desc' }])
                    }
                ));// group.pipe(toArray())
            })
        );
        const subscribe = items.subscribe((val) => {
            itemGroups.push(val);
        });

        resolve(itemGroups);
    })
}


// export const groupToObjectByKey = (
//   allItems: Array<any>,
//   groupKey: string,
//   splitCamelCase = false,
//   groupKey2 = "parentId"
// ): Array<any> => {
//   if (!groupKey || !allItems?.length) return [];

//   const grouped = allItems.reduce((groups, item) => {
//     const key = item[groupKey] || "Other";
//     const formattedKey = splitCamelCase ? splitInitCapitalCamelCase(key) : key;

//     if (!groups[formattedKey]) {
//       groups[formattedKey] = { key: formattedKey, items: [] };
//     }

//     groups[formattedKey].items.push(item);
//     return groups;
//   }, {});

//   // Convert grouped object to an array and process child-parent nesting
//   return Object.values(grouped).map(group => {
//     // Nest child items under their parents
//     const filteredItems = group.items.filter(item => !item[groupKey2]);
//     filteredItems.forEach(parent => {
//       parent.childItems = group.items.filter(child => child[groupKey2] === parent.id);
//     });

//     return { ...group, items: filteredItems };
//   });
// };

export const GroupToObjectByKey = (allItems: Array<any>, groupKey: string, splitCamelCase = false, groupKey2 = "parentId") => {

    return new Promise((resolve, reject) => {
        const itemGroups: Array<any> = [];
        if (!groupKey || !allItems?.length) {
            resolve(itemGroups);
            return;
        }
        const items = from(allItems || []).pipe(
            groupBy((doc) => doc[groupKey]),
            mergeMap((group) => {
                var items = [];
                group.forEach(item => items.push(item));
                return of((
                    {
                        key: splitCamelCase ? splitInitCapitalCamelCase(group?.key || "Other") : group?.key,
                        items: items
                    }
                ));// group.pipe(toArray())
            })
        );
        const subscribe = items.subscribe((val) => {
            itemGroups.push(val);
        });
        itemGroups.forEach(element => {
            element[groupKey] = "" + splitInitCapitalCamelCase(element[groupKey]);

            var filteredItems = element.items.filter(i => !(i.parentId));
            filteredItems.forEach(_item => {
                _item.childItems = element.items.filter(i => i.parentId == _item.id)
            });
            element.items = filteredItems;

        });
        resolve(itemGroups);
    })
}


export const GroupToArrayByKey = (allItems: Array<any>, groupKey: string, splitCamelCase = false, groupKey2 = "parentId") => {

    return new Promise((resolve, reject) => {
        const itemGroups: Array<any> = [];
        if (!groupKey || !allItems?.length) {
            resolve(itemGroups);
            return;
        }
        const items = from(allItems || []).pipe(
            groupBy((doc) => doc[groupKey]),
            mergeMap((group) => {
                var items = [];
                group.forEach(item => items.push(item));
                return group.pipe(toArray())
            })
        );
        const subscribe = items.pipe(tap((element: any) => {
            element[groupKey] = "" + splitInitCapitalCamelCase(element[groupKey]);



            var filteredItems = element?.items?.filter(i => !(i.parentId));
            filteredItems.forEach(_item => {
                _item.childItems = element?.items?.filter(i => i.parentId == _item.id)
            });
            element.items = filteredItems;
            itemGroups.push(element);
        })).toPromise().finally(() => {
            resolve(itemGroups);

        });
        // itemGroups.forEach(element => {
        //     element[groupKey] = "" + splitInitCapitalCamelCase(element[groupKey]);



        //     var filteredItems = element.items.filter(i => !(i.parentId));
        //     filteredItems.forEach(_item => {
        //         _item.childItems = element.items.filter(i => i.parentId == _item.id)
        //     });
        //     element.items = filteredItems;

        // });

    })
}



export const IsGuid = (guid: string) => {
    return (new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i')).test(guid);
}

export const getParameterByName = (name, url) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getFileIconFromFileType = (fileType:string) =>{
    // const fileType = file.type;
    // check if file is a pdf
    if (fileType === 'application/pdf' || fileType === 'pdf') {
      return ('assets/images/pdf.png'); // Optionally provide a fallback image
    }
    // check if file is a text file
    else if (fileType === 'text/plain' || fileType === 'txt') {
      return ('assets/images/txt.png'); // Optionally provide a fallback image
    }
    // chekc  if file is a WORD Document
    else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || fileType === 'doc' || fileType === 'docx') {
      return ('assets/images/doc-file.png'); // Optionally provide a fallback image
    }
    // check if file is an excel file
    else if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'xls' || fileType === 'xlsx') {
      return ('assets/images/excel.png'); // Optionally provide a fallback image
    }
    //check if png or PNG or JPG or jpg or jpeg or JPEG
    else if (fileType === 'image/png' || fileType === 'png' || fileType === 'image/jpeg' || fileType === 'jpeg' || fileType === 'jpg') {
      return ('assets/images/phpto.png'); // Optionally provide a fallback image
    }
    
    // check if file is an image
    else {//if (!fileType.startsWith('image/') && fileType !== 'image') {
      return ('assets/images/unknown-file.png'); // Optionally provide a fallback image
    }
}


//search by relevance
export const searchByRelevance = (input: Array<any>, search: string, keys: Array<string>) => {
    //search in given keys 
    // assign relevance by position of word in given keys and number of words matched in given keys 
    // sort by given keys and relevance
    let searchWords = (search || "")?.toLowerCase()?.split(" ").filter((w) => w.length > 0);

    return (input || []).filter((r) => {
        let relevance = 0
        keys.forEach((key) => {
            let value = r[key]?.toLowerCase();

            if (value) {
                searchWords.forEach((word) => {
                    if (value?.includes(word)) {
                        relevance += 1
                    }
                })
            }
        });
        r.relevance = relevance
        return relevance >= searchWords.length;
    });
}

// sort by relevance
export const sortByRelevance = (input: Array<any>, keys: Array<string>) => {
    // SORT BY RELEVANACE AND GIVEN KEYS


    //   if (a.relevance == b.relevance) {
    //     return a.name.localeCompare(b.name)
    //   }
    //   return b.relevance - a.relevance

    return (input || []).sort((a, b) => {
        if (a.relevance == b.relevance) {
            return keys.map(key => (a[key] + '').localeCompare(b[key] + '')).reduce((a, b) => a || b);
        }
        return b.relevance - a.relevance
    }
    )
}

export function asDecimal(_num: number ): number {
    _num = _num || 0;
    const num = _num.toString().replace(/,/g, '');

    const decimal = new Decimal(num || 0);
    return +decimal.toDP(2);
}

export function asFloat(_num: number): string {
    return `${asDecimal(_num)}`;
}

export function subtractFrom(_minuend: number, _subtrahend: number ) {
    const minuend = _minuend.toString().replace(/,/g, '');
    const subtrahend = _subtrahend.toString().replace(/,/g, '');

    const difference = Decimal.sub(minuend, subtrahend);
    return +difference.toDP(2);
}

export function addTo(_augend: number, _addend: number ) {
    const augend = _augend.toString().replace(/,/g, '');
    const addend = _addend.toString().replace(/,/g, '');

    const sum = Decimal.add(augend, addend);
    return +sum.toDP(2);
}

export function multiplyBy(_multiplicand: number , _multiplier: number ) {
    const multiplicand = _multiplicand.toString().replace(/,/g, '');
    const multiplier = _multiplier.toString().replace(/,/g, '');

    const product = Decimal.mul(multiplicand, multiplier);
    return +product.toDP(2);
}

export function divideBy(_dividend: number, _divisor: number) {
    const dividend = _dividend.toString().replace(/,/g, '');
    const divisor = _divisor.toString().replace(/,/g, '');

    const dividendDecimal = new Decimal(dividend);
    const divisorDecimal = new Decimal(divisor);

    const quotient = Decimal.div(dividendDecimal, divisorDecimal);
    return +quotient.toDP(2);
}