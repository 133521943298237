import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';


@Injectable({
    providedIn: 'root'
})
export class StorageProvider {

    constructor(private storage: Storage) {

        //this = { ...this.storage }
    }

    async get(key: string) {
        return await this.storage.get(key);
    }
    
    async set(key: string, value: any) {
        return await this.storage.set(key, value);
    }

    async clear () {
        return await this.storage.clear();
    }

}
