import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import * as LogRocket from 'logrocket';
import { environment } from 'src/environments/environment';
import { UserInfo } from '../models/auth/auth.model';
import { CompanyInfo } from '../models/company.model';
import { Observable, Observer } from 'rxjs';
import { UserProvider } from '../modules/auth/providers/user.service';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StorageProvider } from '../providers/storage';
import { DEFAULT_VALUES } from '../constants/default.values';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  isMenuOpen: boolean = false;


  _companyInfo: CompanyInfo;
  set companyInfo(_value) {
    this._companyInfo = _value;
    if (_value) {
      this.identifyUser();
    }
    this.companyInfoObserver && this.companyInfoObserver.next(_value);
  };
  get companyInfo(): CompanyInfo {
    return this._companyInfo;
  };

  _userInfo: UserInfo;

  set userInfo(_value: UserInfo) {
    this._userInfo = _value;
    if (_value) {
      this.identifyUser();
    }
    this.userInfoObserver && this.userInfoObserver.next(_value);

    this.companyId = this.userInfo?.companyId;
  };

  get userInfo(): UserInfo {
    return this._userInfo;
  };
  private _companyId: string;

  set companyId(_value: string) {
    this._companyId = _value
  };
  get companyId(): string {
    return this._companyId;
  };


  footerText: string | null = null;
  appVersion: string = '';
  companyInfoObserver: Observer<any> = null;
  companyInfoObservable: Observable<any> = null;
  userInfoObserver: Observer<any> = null;
  userInfoObservable: Observable<any> = null;
  constructor(private storage: StorageProvider,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private deviceDetect: DeviceDetectorService, private modalController: ModalController) {
    // this.isMenuOpen = window.innerWidth > 1366;
  }




  subscribeCompanyInfo() {
    this.companyInfoObservable = this.companyInfoObservable || new Observable((observer) => {
      this.companyInfoObserver = observer;
    })

    this.companyInfo && this.companyInfoObserver && this.companyInfoObserver.next(this.companyInfo);
    return this.companyInfoObservable;
  }
  subscribeUserInfo() {
    this.userInfoObservable = this.userInfoObservable || new Observable((observer) => {
      this.userInfoObserver = observer;
    })

    this.userInfo && this.userInfoObserver && this.userInfoObserver.next(this.userInfo);
    return this.userInfoObservable;
  }

  public formatDate(_date: any) {
    return moment(_date).format('YYYY-MM-DD');
  }
  public async logout() {
    this.userInfo = null;
    this.companyInfo = null;
    await this.storage.clear();

    await this.router.navigateByUrl(`/login`).then(() => {
      setTimeout(() => {
        location.reload();
      }, 500);
    });

  }

  public get deviceInfo() {
    if (this.footerText) {

      return this.footerText;
    }
    else {


      let os = this.deviceDetect.getDeviceInfo().os;
      let browser = this.deviceDetect.getDeviceInfo().browser
      let browserVersion = this.deviceDetect.getDeviceInfo().browser_version;
      let version = this.appVersion != '' ? this.appVersion : this.getVersion();
      this.footerText = `Version: ${version}, OS: ${os}, Browser: ${browser} (${browserVersion.split('.')[0]})`;
      return this.footerText;

    }
  }


  openPage(url, isCompanyPage: boolean = false) {

  }

  public getVersion() {
    this.appVersion = environment?.appVersion || DEFAULT_VALUES.APP_VERSION;
    return this.appVersion;
  }
  isLogRocketInitialized = false;
  isLogRocketUserIdentified = false;

  private initLogRocket() {
    if (!this.isLogRocketInitialized && environment?.initLogRocket) {


      // H.init('xdn184g0', {
      //     environment: 'production',
      //     version: '1.0.0',
      //     networkRecording: {
      //         enabled: true,
      //         recordHeadersAndBody: true,
      //         urlBlocklist: [
      //             // insert full or partial urls that you don't want to record here
      //         ],
      //     },
      // })


      LogRocket.init('geyc3i/inventory', {
        release: this.getVersion()
      })
      this.isLogRocketInitialized = true;
    }
  }

  startNewSession() {
    if (!this.isLogRocketInitialized) {
      this.initLogRocket();

    }
    else {
      LogRocket.startNewSession();
    }

    this.identifyUser();
  }
  identifyUser() {
    if (!this.isLogRocketInitialized) {
      this.initLogRocket();
    }

    if (this.isLogRocketInitialized && this.userInfo && !this.isLogRocketUserIdentified) {

      // H.identify(`${this.userInfo?.email}`, {
      //     id: this.userInfo?.userId,
      //     name: `${this.userInfo?.firstName || ""} ${this.userInfo?.lastName || ""} - ${this.companyInfo?.companyName}`,
      //     company: this.userInfo?.companyName || '',
      //     currentSubscription: `Plan - ${this.companyInfo?.activePlan}`
      // });

      if (this.userInfo?.userId && this.userInfo?.email && this.companyInfo?.companyName) {
        LogRocket.identify(this.userInfo?.userId, {
          name: `${this.userInfo?.firstName || ""} ${this.userInfo?.lastName || ""} - ${this.companyInfo?.companyName}`,
          email: `${this.userInfo?.email}`,
          currentSubscription: `Plan - ${this.companyInfo?.activePlan}`,
        });
        this.isLogRocketUserIdentified = true;
      }

     }
  }



}
