import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'product-list',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'user-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/auth/pages/user-profile/user-profile.module').then(m => m.UserProfilePageModule)
  },
  {
    path: 'email-phone-verify',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/auth/pages/email-phone-verify/email-phone-verify.module').then(m => m.EmailPhoneVerifyPageModule)
  },
  {
    path: 'master-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/master-details/master-details.module').then(m => m.MasterDetailsPageModule)
  },
  {
    path: 'service-category',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/service-category/service-category.module').then(m => m.ServiceCategoryPageModule)
  },
  {
    path: 'service-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/service-list/service-list.module').then(m => m.ServiceListPageModule)
  },
  {
    path: 'sales-orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/sales-orders/sales-orders.module').then(m => m.SalesOrdersPageModule)
  },
  {
    path: 'product-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/product-list/product-list.module').then(m => m.ProductListPageModule)
  },
  {
    path: 'add-product',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/add-product/add-product.module').then(m => m.AddProductPageModule)
  },
  {
    path: 'add-equipment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/add-equipment/add-equipment.module').then(m => m.AddEquipmentPageModule)
  },
  {
    path: 'view-equipment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/view-equipment/view-equipment.module').then(m => m.ViewEquipmentPageModule)
  },
  {
    path: 'product-category-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/product-category-list/product-category-list.module').then(m => m.ProductCategoryListPageModule)
  },
  {
    path: 'add-product-category',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/add-product-category/add-product-category.module').then(m => m.AddProductCategoryPageModule)
  },
  {
    path: 'add-equipment-category',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/add-equipment-category/add-equipment-category.module').then(m => m.AddEquipmentCategoryPageModule)
  },
  {
    path: 'supplier-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/supplier-list/supplier-list.module').then(m => m.SupplierListPageModule)
  },
  {
    path: 'add-supplier',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/add-supplier/add-supplier.module').then(m => m.AddSupplierPageModule)
  },
  {
    path: 'vendor-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/vendor-list/vendor-list.module').then(m => m.VendorListPageModule)
  },
  {
    path: 'add-product-supplier',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/add-product-supplier/add-product-supplier.module').then(m => m.AddProductSupplierPageModule)
  },
  {
    path: 'view-product-supplier',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/view-product-supplier/view-product-supplier.module').then(m => m.ViewProductSupplierModule)
  },
  {
    path: 'view-supplier',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/view-supplier/view-supplier.module').then(m => m.ViewSupplierModule)
  },
  {
    path: 'view-product',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/view-product/view-product.module').then(m => m.ViewProductPageModule)
  },
  {
    path: 'product-supplier-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/product-supplier-list/product-supplier-list.module').then(m => m.ProductSupplierListPageModule)
  },
  {
    path: 'purchase-order-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/purchase-order-list/purchase-order-list.module').then(m => m.PurchaseOrderListPageModule)
  },
  {
    path: 'add-purchase-order',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/add-purchase-order/add-purchase-order.module').then(m => m.AddPurchaseOrderPageModule)
  },
  {
    path: 'receiving-order-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/receiving-order-list/receiving-order-list.module').then(m => m.ReceivingOrderListPageModule)
  },
  {
    path: 'back-order-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/back-order-list/back-order-list.module').then(m => m.BackOrderListPageModule)
  },
  {
    path: 'add-receiving-order',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/add-receiving-order/add-receiving-order.module').then(m => m.AddReceivingOrderPageModule)
  },
  {
    path: 'add-back-order',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/add-back-order/add-back-order.module').then(m => m.AddBackOrderPageModule)
  },
  {
    path: 'change-password',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/auth/pages/change-password/change-password.module').then(m => m.ChangePasswordPageModule)
  },
  {
    path: 'edit-purchase-order',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/edit-purchase-order/edit-purchase-order.module').then(m => m.EditPurchaseOrderPageModule)
  },
  {
    path: 'product-stock-history-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/product-stock-history-list/product-stock-history-list.module').then(m => m.ProductStockHistoryListPageModule)
  },
  {
    path: 'view-receiving-order',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/view-receiving-order/view-receiving-order.module').then(m => m.ViewReceivingOrderPageModule)
  },
  {
    path: 'view-service-order',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/view-service-order/view-service-order.module').then( m => m.ViewServiceOrderPageModule)
  },

  {
    path: 'equipment-category-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/equipment-category-list/equipment-category-list.module').then(m => m.EquipmentCategoryListPageModule)
  },
  {
    path: 'equipment-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/inventory/pages/equipment-list/equipment-list.module').then(m => m.EquipmentListPageModule)
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./modules/inventory/pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'generate-receiving-order',
    loadChildren: () => import('./modules/inventory/pages/generate-receiving-order/generate-receiving-order.module').then( m => m.GenerateReceivingOrderPageModule)
  },
  {
    path: 'receiving-order-without-po',
    loadChildren: () => import('./modules/inventory/pages/receiving-order-without-po/receiving-order-without-po.module').then( m => m.ReceivingOrderWithoutPoPageModule)
  },











];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
