import { Component, ElementRef, Injectable, ViewChild } from "@angular/core";
import { set } from "lodash";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { finalize, switchMap, takeUntil } from "rxjs/operators";
import 'reflect-metadata';




@Injectable()
abstract class DataSubscriberService {
  destroy$: Subject<boolean> = new Subject();
  destroyOnExit$: Subject<boolean> = new Subject();
  private activeRequest$: Subject<any> = new Subject();
  private paramsSubject: BehaviorSubject<any> = new BehaviorSubject({});
  protected serviceDestroyed = false; // Flag to ensure single call to parent

  constructor() {

  }

  cleanUp() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroyOnExit$.next(true);
    this.destroyOnExit$.complete();
  }

  ngOnDestroy(): void {
    // console.log('Destroying service component...');
    // this.destroy$.next(true);
    // this.destroy$.complete();
    this.cleanUp();
    this.cancelPreviousRequest();  // Ensure the active request is cleaned up as well
  }


  /**
   * Subscribe to the given observable until destroyed
   * @param next Will handle values emitted by observable according to the type set
   * - String: will set the property by this name on derived class
   * - Function: will run function with value
   **/
  untilDestroyed<T>(observable: Observable<T>, next?: ((value: T) => any) | string, error?: ((err: any) => void)) {
    observable
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (typeof next === 'string') {
          set(this, next as keyof this, value);
        } else {
          (next as Function)(value);
        }
      },
        err => {
          if (error) {
            if (typeof error === 'string') {
              set(this, error as keyof this, err);
            } else {
              (error as Function)(err);
            }
            ///error(err);  // Invoke the error handler if provided
          } else {
            console.error('Error in subscription:', err); // Default error handling
          }
        });
  }
  // untill entered

  /**
   * Subscribe to the given observable until destroyed
   * @param next Will handle values emitted by observable according to the type set
   * - String: will set the property by this name on derived class
   * - Function: will run function with value
   **/
  untilEntered<T>(observable: Observable<T>, next?: ((value: T) => any) | string, error?: ((err: any) => void)) {
    observable
      .pipe(takeUntil(this.destroyOnExit$))
      .subscribe(value => {
        if (typeof next === 'string') {
          set(this, next as keyof this, value);
        } else {
          (next as Function)(value);
        }
      },
        err => {

          if (error) {
            if (typeof error === 'string') {
              set(this, error as keyof this, err);
            } else {
              (error as Function)(err);
            }
            ///error(err);  // Invoke the error handler if provided
          } else {
            //console.error('Error in subscription:', err); // Default error handling
          }
        });
  }

  ionViewDidLeave() {
    // this.destroyOnExit$.next(true);
    // this.destroyOnExit$.complete();
    this.cleanUp();
    // this.cancelPreviousRequest();  // Ensure the active request is cleaned up as well
  }


  /**
   * Handle the API request with cancellation mechanism.
   * It ensures cancellation of previous requests before starting a new one.
   * @param apiMethod API method that returns an observable
   * @param params Params for the API request
   * @param onSuccess Callback for success
   * @param onError Callback for error handling
   */
  loadDataWithCancellation<T>(
    apiMethod: (...args: any[]) => Observable<T>,
    params: any,
    onSuccess?: (value: T) => any,
    onError?: (err: any) => void
  ): void {
    // Cancel previous request if any
    this.cancelPreviousRequest();

    // Start new request with cancellation mechanism
    const newRequest$ = apiMethod(params).pipe(
      takeUntil(this.destroy$), // Ensure cleanup when the component is destroyed
      finalize(() => {
        console.log('Request complete or canceled. Cleaning up...');
        this.activeRequest$.next(null); // Reset the active request subject
      })
    );

    // Subscribe and handle the response
    newRequest$.subscribe({
      next: (value) => {
        if (onSuccess) onSuccess(value);
      },
      error: (err) => {
        if (onError) onError(err);
      }
    });
  }

  /**
 * Cancel the previous request (if any) before making a new one.
 */
  private cancelPreviousRequest(): void {
    this.activeRequest$.next(null);  // Trigger cancellation of the ongoing request
    this.activeRequest$.complete();  // Complete the active request subject
    this.activeRequest$ = new Subject();  // Create a new subject for the next request
  }


  /**
* Update dynamic parameters that trigger new data requests.
* @param params The parameters to update (e.g., page number, search query)
*/
  updateParams(params: any) {
    //console.log('Updating params:', params);
    this.paramsSubject.next(params);
  }

  /**
  * Get the observable for dynamic parameters that will trigger new data requests when changed.
  */
  getParamsObservable(): Observable<any> {
    return this.paramsSubject.asObservable();
  }


  /**
 * Subscribe to the parameters and trigger data load when parameters change.
 * @param apiMethod API method that returns an observable
 * @param onSuccess Callback for success
 * @param onError Callback for error handling
 */
  subscribeToParamsAndLoadData<T>(
    apiMethod: (...args: any[]) => Observable<T>,
    onSuccess: (value: T) => any,
    onError: (err: any) => void
  ): void {
    // Subscribe to the params subject
    this.paramsSubject
      .pipe(
        switchMap((params) => {
          // On params change, cancel any ongoing requests and make an API call with the updated params
          this.cancelPreviousRequest(); // Cancel the previous request here
          // return apiMethod(params);  // Call API method with the latest params

          if (params && Object.keys(params).length > 0) {
            // Make sure there's an actual params object before calling API
            return apiMethod(params);
          } else {
            return new Observable<T>(); // Return empty observable if no params
          }

        }),
        takeUntil(this.destroy$) // Ensure we cancel when the component is destroyed
      )
      .subscribe({
        next: (value) => {
          onSuccess(value);  // Pass the data to the onSuccess callback
        },
        error: (err) => {
          onError(err);  // Pass the error to the onError callback
        },
      });
  }
}


export function EnsureParentNgOnDestroy() {
  return function (target: any) {
    Reflect.defineMetadata('ensureParentDestroy', true, target); // Mark the class as decorated

    const originalOnDestroy = target.prototype.ngOnDestroy;

    target.prototype.ngOnDestroy = function () {
      // Call the parent's ngOnDestroy if it exists
      const parentOnDestroy = Object.getPrototypeOf(target.prototype)?.ngOnDestroy;
      if (parentOnDestroy) {
        parentOnDestroy.apply(this);
      }

      // Call the child's ngOnDestroy (if it exists)
      if (originalOnDestroy) {
        originalOnDestroy.apply(this);
      }
    };
  };
}


@Component({
  template: ''
})

export abstract class DataSubscriber extends DataSubscriberService {

  constructor() {
    super();
    // console.log('Creating the base component...', this.constructor);
    // Check if the child class has been decorated with @EnsureParentNgOnDestroy()
    const isDecorated = Reflect.getMetadata('ensureParentDestroy', this.constructor);
    if (!isDecorated) {
      throw new Error(
        `Classes extending DataSubscriber must be decorated with @EnsureParentNgOnDestroy()!`
      );
    }
    // this.enforceSuperDestroy();
  }

  override ngOnDestroy(): void {
    // super.ngOnDestroy();
    if (!this.serviceDestroyed) {
      super.ngOnDestroy(); // Call the parent once
      this.serviceDestroyed = true; // Prevent duplicate calls
    }
    // console.log('Destroying the base component...', this.constructor);
  }

}
