import { Component } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Route, Router } from '@angular/router';
import { UserProvider } from './modules/auth/providers/user.service';
// import { AuthService } from './modules/auth/services/auth.service';
import { Platform, PopoverController } from '@ionic/angular';
// import { ApiResponse } from './models/common.model';
import { NotificationPopoverComponent } from './modules/auth/components/notification-popover/notification-popover.component';
import { HeaderUserPopoverComponent } from './modules/auth/components/header-user-popover/header-user-popover.component';
import { Storage } from '@ionic/storage-angular';
import { GlobalService } from './services/global.service';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { initializeApp as initializeFireBaseApp } from 'firebase/app';
import { getAnalytics, logEvent } from "firebase/analytics";
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  userData: any;
  profileData: any;
  pageTitle: any;
  profile: any;
  notifications: any;
  companyInfo: any;
  constructor(
    public userProvider: UserProvider,
    // private authService: AuthService,
    public popoverCtrl: PopoverController,
    private router: Router,
    private storage: Storage,
    private gbService: GlobalService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private activatedRoute: ActivatedRoute
  ) { }

  async ngOnInit() {

    this.gbService.startNewSession();

    this.storage.create();

    this.initializeApp();


    this.router.events
      .pipe(
        filter((e: Event) => e instanceof NavigationEnd)
      )
      .subscribe(async (event: any) => {
        const screenName = `inventory-app${this.getScreenName(this.activatedRoute)}`;
        try {
          logEvent(getAnalytics(), 'screen_view', {
            firebase_screen: screenName,
            firebase_screen_class: screenName
          });
        }
        catch (e) {
          console.log('error setting screen name', e)
        }

      })

  }


  async openNotification(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: NotificationPopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: { 'notifications': this.notifications }
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
  }

  async userPopover(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: HeaderUserPopoverComponent,
      componentProps: {},
      event: ev
    });
    await popover.present();
  }



  isActive(page) {
    return this.router.url.split('?')[0] == page
  }

  gotoPage(page) {
    this.router.navigate([page])
  }

  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    if (Capacitor.getPlatform() == 'web') {
      //FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
      const app = initializeFireBaseApp(environment.firebaseConfig);
    }
    this.storage.create();

  }


  getScreenName(route: ActivatedRoute): string {
    let path = '';

    // Traverse the route snapshot tree to build the static path
    while (route) {
      const snapshot = route.snapshot;
      const urlSegments = snapshot.url.map(segment => segment.path).join('/');

      // Check if any part of the route path is dynamic
      const hasDynamicSegment = this.hasDynamicSegment(route.routeConfig);

      // If a dynamic segment is found, add only the static part and stop
      if (hasDynamicSegment) {
        // Add static URL segments to path
        path += `/${urlSegments.split('/')[0]}`;  // Only add the first part of the segment
        break;  // Stop adding segments after the dynamic part is encountered
      }

      // If no dynamic segment, add the whole URL
      if (urlSegments) {
        path += `/${urlSegments}`;
      }

      // Move to the child route
      route = route.firstChild!;
    }

    return path.startsWith('/') ? path : `/${path}`;
  }

  /**
   * Checks if the given route config has a dynamic segment (e.g., ':id').
   */
  hasDynamicSegment(routeConfig?: Route | null): boolean {
    if (!routeConfig || !routeConfig.path) return false;

    // Check if the path contains any dynamic segments (e.g., ':id')
    return routeConfig.path.split('/').some(segment => segment.startsWith(':'));
  }
}
