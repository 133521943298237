import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpService } from './services/http.service';
import { StorageService } from './services/storage.service';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import {NgxPrintModule} from 'ngx-print';
import { UserProvider } from './modules/auth/providers/user.service';
import { Storage } from '@ionic/storage-angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
// import { NgxBarcode6Module } from 'ngx-barcode6';



@NgModule({
  declarations: [AppComponent, ],
  imports: [HttpClientModule,BrowserModule, IonicModule.forRoot(), AppRoutingModule,OwlDateTimeModule, OwlNativeDateTimeModule,
    BrowserAnimationsModule, NoopAnimationsModule,NgxPrintModule,
    // NgxBarcode6Module
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },HttpService,StorageService,UserProvider, Storage,
    StatusBar,
    SplashScreen,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
