import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InfiniteScrollCustomEvent, PopoverController } from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import { ApiResponse } from 'src/app/models/common.model';
import { AlertMsgProvider } from 'src/app/services/alert-message.service';

@Component({
  selector: 'app-notification-popover',
  templateUrl: './notification-popover.component.html',
  styleUrls: ['./notification-popover.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class NotificationPopoverComponent implements OnInit {
  notifications: any[] = [];

  pageNumber: number = 0;
  pageSize: number = 10;
  totalCount: number = 0;
  newNotificationsCount: number = 0;

  constructor(public router: Router, private alertMsgProvider: AlertMsgProvider, private authService: AuthService,
    private changeDetectionRef: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.notifications = [];
    this.getNotifications();
  }

  async getNotifications() {
    let body = {
      pageNumber: this.pageNumber++,
      pageSize: this.pageSize
    }

    try {
      const res = await this.authService.getNotification(body).toPromise();

      if (this.pageNumber == 1) {
        this.totalCount = res?.totalCount || 0;
      }
      else {
        if (this.totalCount != res?.totalCount) {
          this.newNotificationsCount = res?.totalCount - this.totalCount;
          this.newNotificationsCount = this.newNotificationsCount < 0 ? 0 : this.newNotificationsCount;
          this.totalCount = res?.totalCount || 0;
        }
      }

      this.notifications = this.notifications.concat(res?.output || []);
      this.changeDetectionRef.detectChanges();
    } catch (error) {
      this.alertMsgProvider.ResponseErrorAlert(error);

    }

  }

  viewAll() {
    this.alertMsgProvider.dismissPopover();
    this.router.navigate(['view-notifications'])
  }

  async onIonInfinite(ev) {
    //setTimeout(() => {
    await this.getNotifications();
    (ev as InfiniteScrollCustomEvent).target.complete();
  
    //}, 500);
  }

  ngOnDestory() {
    delete this.notifications;
  }
}
