import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiResponse, ResponseStatus } from 'src/app/models/common.model';
import { AlertMsgProvider } from 'src/app/services/alert-message.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from '../services/auth.service';
import { GlobalService } from 'src/app/services/global.service';
import * as LogRocket from 'logrocket';
import { environment } from 'src/environments/environment';
import { Observable, Observer, tap } from 'rxjs';
import { UserInfo } from 'src/app/models/auth/auth.model';
import { CompanyInfo } from 'src/app/models/company.model';

@Injectable({
  providedIn: 'root'
})
export class UserProvider {







  // _companyInfo: CompanyInfo;
  // set companyInfo(_value) {
  //   this._companyInfo = _value;
  //   if (_value) {
  //     this.identifyUser();
  //   }
  //   this.companyInfoObserver && this.companyInfoObserver.next(_value);
  // };
  // get companyInfo(): CompanyInfo {
  //   return this._companyInfo;
  // };

  // _userInfo: UserInfo;

  // set userInfo(_value: UserInfo) {
  //   this._userInfo = _value;
  //   if (_value) {
  //     this.identifyUser();
  //   }
  //   this.userInfoObserver && this.userInfoObserver.next(_value);

  //   this.companyId = this.userInfo?.companyId;
  // };

  // get userInfo(): UserInfo {
  //   return this._userInfo;
  // };
  // private _companyId: string;

  // set companyId(_value: string) {
  //   this._companyId = _value
  // };
  // get companyId(): string {
  //   return this._companyId;
  // };

  // footerText: string | null = null;
  // appVersion: string = '';
  // companyInfoObserver: Observer<any> = null;
  // companyInfoObservable: Observable<any> = null;
  // userInfoObserver: Observer<any> = null;
  // userInfoObservable: Observable<any> = null;













  constructor(private authService: AuthService, private alertMsgProvider: AlertMsgProvider,
    private router: Router, private storageService: StorageService,
    private globalService: GlobalService
  ) { }

  login(data) {
    this.alertMsgProvider.loading().then(() => {
      this.authService.login(data).toPromise().then((response: ApiResponse) => {
        this.alertMsgProvider.ResponseAlert(response, 'User', 'Login', 'Combine', (data: ApiResponse) => {
          // this.startNewSession();
          this.globalService.userInfo = data.output;
          const { token, companyId, tokenExpiry } = response.output || {};
          this.setAccessToken({ token, companyId, tokenExpiry });



          if (!data.output.emailConfirmed || !data.output.phoneNumberConfirmed) {
            this.router.navigateByUrl('/email-phone-verify');
          } else {
            this.router.navigateByUrl('/dashboard');
          }

        }, () => {}, false)
      }, (error) => {
        this.alertMsgProvider.ResponseErrorAlert(error);
      });
    });
  }


  
  loginWithToken(data) {
    
      return this.authService.loginWithToken(data).pipe(tap((response: ApiResponse) => {

        this.globalService.userInfo = data.output;
        const { token, companyId, tokenExpiry } = response.output || {};
        this.setAccessToken({ token, companyId, tokenExpiry });

      }));
    
  }




  getAccessToken(logoutOnFailure: boolean = true) {
    const token = this.storageService.getLocal("token") || "";
    const companyId = this.storageService.getLocal("companyId") || "";
    const tokenExpiry = this.storageService.getLocal("tokenExpiry") || "";
    if (!token && logoutOnFailure) {
      this.logout();
    }
    else if (!tokenExpiry && logoutOnFailure) {
      this.logout();
      //await this.storage.clear();
      //return "";
    }
    const dt1: any = new Date();
    const dt2: any = new Date(tokenExpiry);
    var c = dt2 - dt1;
    if (c <= 0 && logoutOnFailure) {
      this.logout();
      //await this.storage.clear();
      //return "";
    }
    // else {
    //   //return token;
    // }

    return { token, companyId, tokenExpiry };
  }


  isInfoLoading: boolean = false;
  

  logout() {
    this.storageService.clear();
    this.globalService.userInfo = null;
    this.globalService.companyInfo = null;
    this.router.navigate(['/login']);
    this.globalService.isLogRocketInitialized = false;
  }



  setAccessToken(tokenInfo: { token: string, companyId: string, tokenExpiry: string }) {


    this.storageService.localStore("token", tokenInfo?.token || "");
    this.storageService.localStore("companyId", tokenInfo?.companyId || "");
    this.storageService.localStore("tokenExpiry", tokenInfo?.tokenExpiry || "");
  }



  // async getLoggedInUserProfile() {
  //   this.isInfoLoading = true;
  //   let response: any = await this.authService.getLoggedInUserProfile().toPromise();
  //   response.status == ResponseStatus.Ok ? this.userInfoObserver.next(response.output) : this.alertMsgProvider.ResponseErrorAlert(response);
  //   this.isInfoLoading = false;
  // }





  // subscribeCompanyInfo() {
  //   this.companyInfoObservable = this.companyInfoObservable || new Observable((observer) => {
  //     this.companyInfoObserver = observer;
  //   })

  //   this.companyInfo && this.companyInfoObserver && this.companyInfoObserver.next(this.companyInfo);
  //   return this.companyInfoObservable;
  // }
  // async subscribeUserInfo() {
  //   if (!this.userInfoObservable) {
  //     this.userInfoObservable = new Observable((observer) => {
  //       this.userInfoObserver = observer;
  //     })
  //   }

  //   this.userInfo && this.userInfoObserver && this.userInfoObserver.next(this.userInfo);
  //   if ((!this.userInfo || !this.userInfo?.userId) && !this.isInfoLoading) {
  //     this.getLoggedInUserProfile();
  //   }
  //   return this.userInfoObservable;
  // }




  // isLogRocketInitialized = false;

  // private initLogRocket() {

  //   // this.userInfoObservabletoPromise().then((userInfo) => {
  //   //   this.userInfo = userInfo;

  //     if (!this.isLogRocketInitialized && environment?.initLogRocket) {

  //       LogRocket.init('geyc3i/inventory', {
  //         release: '1.0.0'//this.getVersion()
  //       })
  //       this.isLogRocketInitialized = true;
  //     }
  //   // });
  // }

  // startNewSession() {
  //   if (!this.isLogRocketInitialized) {
  //     this.initLogRocket();

  //   }
  //   else {
  //     LogRocket.startNewSession();
  //   }

  //   this.identifyUser();
  // }

  // identifyUser() {

  //   if (!this.isLogRocketInitialized) {
  //     this.initLogRocket();
  //   }

  //   if (this.isLogRocketInitialized && this.userInfo) {

  //     // H.identify(`${this.userInfo?.email}`, {
  //     //     id: this.userInfo?.userId,
  //     //     name: `${this.userInfo?.firstName || ""} ${this.userInfo?.lastName || ""} - ${this.companyInfo?.companyName}`,
  //     //     company: this.userInfo?.companyName || '',
  //     //     currentSubscription: `Plan - ${this.companyInfo?.activePlan}`
  //     // });

  //     LogRocket.identify(this.userInfo?.userId, {
  //       name: `${this.userInfo?.firstName || ""} ${this.userInfo?.lastName || ""} - ${this.companyInfo?.companyName}`,
  //       email: `${this.userInfo?.email}`,
  //       currentSubscription: `Plan - ${this.companyInfo?.activePlan}`,
  //     });
  //   }
  // }

}
