import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserProvider } from '../../providers/user.service';
import { GlobalService } from 'src/app/services/global.service';
import { AlertMsgProvider } from 'src/app/services/alert-message.service';
import { DataSubscriber, EnsureParentNgOnDestroy } from 'src/app/utils/data-subscriber';
import { AuthService } from '../../services/auth.service';
import { ApiResponse } from 'src/app/models/common.model';
//import {UserService } from '../../../providers/user/user.service';
//import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-header-user-popover',
  templateUrl: './header-user-popover.component.html',
  styleUrls: ['./header-user-popover.component.scss'],
})
@EnsureParentNgOnDestroy()
export class HeaderUserPopoverComponent extends DataSubscriber implements OnInit {
  userData: any;

  constructor(private router: Router, public userProvider: UserProvider,
    private alertMsgProvider: AlertMsgProvider,
    public gbService: GlobalService,
    private authService: AuthService,
  ) {
    super();
  }
  // override ngOnDestroy(){
  //   //super.ngOnDestroy();
  //   console.log('ChildComponent ngOnDestroy called');
  // }

  async ngOnInit() {
    this.untilDestroyed(this.gbService.subscribeUserInfo(), (_userData: any) => {
      this.userData = _userData;
    });
    // (await this.gbServicetoPromise().thenUserInfo()).toPromise().then((data) => {
    //   this.userData = data;
    // });
    this.userData = this.gbService.userInfo;
    if (!this.gbService.userInfo?.userId) {
      this.authService.getLoggedInUserProfile().toPromise().then((res: ApiResponse) => {
        this.gbService.userInfo = res.output || {};
      })
    }
  }

  logout() {
    this.alertMsgProvider.dismissPopover();
    this.userProvider.logout();
  }

  gotoUserProfile() {
    this.alertMsgProvider.dismissPopover();
    this.router.navigateByUrl('/user-profile');
  }

  gotoChangePassword() {
    this.alertMsgProvider.dismissPopover();
    this.router.navigateByUrl('/change-password');
  }
}
